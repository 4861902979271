<template>
  <div class="container p-tb-2">
    <div class="row">
      <div class="col-lg-12 col-xs-12 our-services">
        <h1 class="text-center">Our Services</h1>
        <p>
          We offer a wide range of high-quality services from customization, production, quality assurance to the
          delivery of the devices to our customers. We support our clients all the way from the moment of ordering to
          delivery.
        </p>
        <div
          class="block button bg-light-green rounded-5 uppercase bordered-light contact-us-btn"
          @click="scrollToForm"
        >
          <span class="block text-bold text-color-white">Contact Us</span>
        </div>
        <ul class="services-list">
          <template v-for="(item, i) in services">
            <li :key="i">
              <div class="icon-handler">
                <div :style="{ 'background-image': `url(${item.icon})` }"></div>
              </div>
              <div class="content-handler">
                <h3 class="service-title">{{ item.title }}</h3>
                <p class="service-description">{{ item.text }}</p>
                <div
                  v-if="item.button"
                  class="block button bg-light-green rounded-5 uppercase bordered-light service-btn"
                  @click="scrollToForm(item)"
                >
                  <span class="block text-bold text-dark text-color-white">{{ item.button.title }}</span>
                </div>
              </div>
            </li>
          </template>
        </ul>
        <h2 class="text-center contact-us-heading" ref="contactUs">Contact Us</h2>
        <div class="contact-us">
          <div class="image-handler">
            <img class="contact-us-image" src="/img/services/contact-us.png" alt="contact-us.png" />
          </div>
          <div class="form-handler">
            <ContactUsForm :request-types="types" :default-type="defType" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ContactUsForm from 'Components/ContactUsForm';
import Services from 'Lib/enums/Services';
import MetaMixin from 'Lib/mixins/MetaMixin';

export default {
  name: 'OurServices',
  mixins: [MetaMixin],
  components: { ContactUsForm },
  computed: {
    services() {
      return Services;
    },
    types() {
      return this.services.map(el => {
        return { text: el.title, value: el.title };
      });
    },
  },
  methods: {
    scrollToForm(item = null) {
      const form = this.$refs.contactUs;
      this.defType = item ? item.title : null;
      if (form) {
        form.scrollIntoView({ block: 'start', behavior: 'smooth' });
      }
    },
    runServiceBtnAction(item) {
      const {
        button: { action },
      } = item;
      if (!action) {
        return;
      }
      if (typeof action === 'function') {
        return void action(item);
      }
    },
  },
  data() {
    return {
      defType: null,
    };
  },
  beforeMount() {
    this.$store.dispatch('setBreadcrumbs', [{ to: this.$route.path, title: 'Our Services' }]);
  },
};
</script>
